import React, { Suspense } from 'react';
import loadable from '@loadable/component';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Loading } from 'pages';
import { theme } from 'common/theme';
import 'common/styles/common.scss';

const PlatformRender = loadable(
    () => import(`platform/${process.env.REACT_APP_PLATFORM}`),
    {
        fallback: <Loading />
    }
);

function App() {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Suspense fallback={<Loading />}>
                    <PlatformRender />
                </Suspense>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
