import React from 'react';
import { Box } from '@mui/material';
import { LoadingImg } from 'common/assets/images';

import './Loading.scss';

export default function Loading() {
    return (
        <Box className="loadingWrapper">
            <Box className="logo">
                <img
                    src={
                        process.env.PUBLIC_URL +
                        process.env.REACT_APP_LOGO_VERTICAL_PATH
                    }
                    alt=""
                />
            </Box>
            <Box className="loading">
                <img src={LoadingImg} alt="" />
            </Box>
        </Box>
    );
}
