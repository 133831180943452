import { createTheme } from '@mui/material/styles';
import { FONTSIZE, COLOR } from 'common/constants/appInfos';

export const theme = createTheme({
    spacing: 2,
    typography: {
        fontFamily: ['LibreFranklin', 'NotoSansCJKsc'].join(',')
    },
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'standard'
            }
        },
        MuiFormControl: {
            defaultProps: {
                variant: 'standard'
            }
        },
        MuiSelect: {
            defaultProps: {
                variant: 'standard'
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: COLOR.BLACK
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    zIndex: 1201
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: COLOR.BLACK
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                message: {
                    fontSize: FONTSIZE.BODY
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                labelPlacementStart: {
                    marginRight: 0
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: FONTSIZE.BODY
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: COLOR.GREEN
                }
            }
        }
    }
});
